import styled from "@emotion/styled/macro";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { MQ } from "react-app/src/styles/helpers";
import { css } from "@emotion/react";

const { spaces } = xxlTheme;

export const ComponentWrapper = styled.div`
  width: 100%;
  margin: 0 0 ${spaces.large};
  position: relative;

  ${MQ("laptop")} {
    margin: 0 0 ${spaces.big};
  }
`;

export const BannerComponentWrapper = styled.div();

export const Section = styled.section(css`
  margin: ${spaces.smallRegular} 0 0;

  ${MQ("laptop")} {
    margin: ${spaces.large} 0 0;
  }
`);

export const AllComponentsWrapper = styled.div(css`
  ${BannerComponentWrapper}:has(+ ${BannerComponentWrapper}) {
    ${ComponentWrapper} {
      margin: 0 0 ${spaces.mini};

      ${MQ("laptop")} {
        margin: 0 0 ${spaces.smallRegular};
      }
    }
  }
`);
