import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import {
  MQ,
  interactionFeedback,
  typographyToCss,
} from "react-app/src/styles/helpers";
import spaces from "react-app/src/styles/theme/spaces";
import type * as CSSType from "csstype";
import { color } from "@xxl/theme";
import NextLink from "next/link";
import { containerMaxWidthPx } from "react-app/src/styles/xxl-theme";

export const DisclaimerInformationWrapper = styled.div`
  position: absolute;
  left: ${spaces.smallRegular};
  top: ${spaces.smallRegular};
  z-index: 1;

  ${MQ("laptop")} {
    left: ${spaces.large};
    top: ${spaces.large};
  }
`;

export const HeaderContainer = styled.div<{
  backgroundColor?: CSSType.Property.Color;
}>(
  ({ backgroundColor }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;
    width: 100%;
    max-width: ${containerMaxWidthPx};
    box-sizing: border-box;
    padding: ${spaces.smallRegular};
    position: absolute;
    inset: 0;
    background-color: ${backgroundColor !== undefined
      ? backgroundColor
      : color.webBlack.hex};

    ${MQ("laptop")} {
      flex-direction: row;
      height: 210px;
      padding: ${spaces.large};
    }
  `
);

export const TextContainer = styled.div<{
  hasDisclaimerInformation: boolean;
  disclaimerInformationIconsSize: number;
}>(
  ({ hasDisclaimerInformation, disclaimerInformationIconsSize }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${spaces.smallRegular};
    padding-top: ${hasDisclaimerInformation
      ? `calc(${spaces.smallRegular} + ${disclaimerInformationIconsSize}px)`
      : 0};
  `
);

export const Title = styled.h2(css`
  margin: 0;
  ${typographyToCss({
    fontSize: 36,
    fontFamily: "var(--font-family-bold)",
    lineHeight: 1,
    letterSpacing: -0.36,
    laptop: {
      fontSize: 60,
      letterSpacing: -1.2,
    },
  })}
`);

export const Description = styled.p(css`
  margin: 0;
  ${typographyToCss({
    fontSize: 15,
    fontFamily: "var(--font-family-bold)",
    lineHeight: 1.2,
    letterSpacing: -0.15,
    laptop: {
      fontSize: 24,
      lineHeight: 1.117,
      letterSpacing: -0.24,
    },
  })}
`);

export const Link = styled(NextLink)(css`
  ${typographyToCss({
    fontSize: 12,
    fontFamily: "var(--font-family-medium)",
    lineHeight: 1.5,
    letterSpacing: -0.12,
  })}
  ${interactionFeedback(`
      text-decoration: underline;
    `)}
`);
