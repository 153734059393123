import type {
  PartialProductRecommendation,
  CampaignCarousel as CampaignCarouselType,
} from "@xxl/content-api";
import { RecommendationsParametersStrategyEnum as Strategy } from "@xxl/recommendations-api";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import spaces from "react-app/src/styles/theme/spaces";
import { CampaignCarousel } from "./CampaignCarousel";
import { Handler as ProductList } from "../PersonalizedProductLists/Handler/Handler";
import { stringToStrategyEnum } from "../PersonalizedProductLists/Handler/Handler.helper";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { color } from "@xxl/theme";
import { containerMaxWidthPx } from "react-app/src/styles/xxl-theme";
import { log } from "@xxl/logging-utils";

type CampaignCarouselComponentProps = {
  module: CampaignCarouselType;
};

const Slider = ({
  config: {
    strategy,
    productsCount,
    categoryCodes,
    days,
    brandNames,
    campaignIds,
    productIds,
  },
}: {
  config: PartialProductRecommendation;
}) => {
  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");
  const isSiteWidth = useXxlMediaQuery("SiteContainerMediaQuery");
  const strategyEnumstrategyEnum = stringToStrategyEnum(strategy);

  if (strategyEnumstrategyEnum === null) {
    log.error("Strategy is missing for Campaign Carousel product carousel.");
    return null;
  }

  const name = "campaign-carousel-component";
  const count = productsCount ?? 16;
  const paddingTop = isLaptopSize ? spaces.big : spaces.smallRegular;
  const type = "SUB_COMPONENT";
  const sliderStyles = isSiteWidth
    ? {
        width: "100%",
        maxWidth: containerMaxWidthPx,
        paddingLeft: spaces.huge,
      }
    : {};

  if (strategy === Strategy.popularity && isNotNullOrUndefined(categoryCodes)) {
    return (
      <ProductList
        campaigns={campaignIds}
        includedProducts={productIds}
        name={name}
        productsCount={count}
        type={type}
        paddingTop={paddingTop}
        strategy={strategy}
        categories={categoryCodes}
        sliderStyles={sliderStyles}
      />
    );
  }

  if (strategy === Strategy.popularity) {
    return (
      <ProductList
        campaigns={campaignIds}
        includedProducts={productIds}
        name={name}
        productsCount={count}
        type={type}
        paddingTop={paddingTop}
        strategy={strategy}
        categories={categoryCodes ?? []}
        sliderStyles={sliderStyles}
      />
    );
  }

  if (strategy === Strategy.bestseller) {
    const nrOfDays = days ?? 7;
    if (isNotNullOrUndefined(categoryCodes)) {
      return (
        <ProductList
          campaigns={campaignIds}
          includedProducts={productIds}
          name={name}
          productsCount={count}
          type={type}
          paddingTop={paddingTop}
          strategy={strategy}
          nrOfDays={nrOfDays}
          brands={brandNames}
          categories={categoryCodes}
          sliderStyles={sliderStyles}
        />
      );
    }

    if (isNotNullOrUndefined(brandNames)) {
      return (
        <ProductList
          campaigns={campaignIds}
          includedProducts={productIds}
          name={name}
          productsCount={count}
          type={type}
          paddingTop={paddingTop}
          strategy={strategy}
          nrOfDays={nrOfDays}
          brands={brandNames}
          categories={categoryCodes}
          sliderStyles={sliderStyles}
        />
      );
    }
  }

  if (strategy === Strategy.personalized) {
    return (
      <ProductList
        campaigns={campaignIds}
        includedProducts={productIds}
        name={name}
        productsCount={count}
        type={type}
        paddingTop={paddingTop}
        strategy={strategy}
        sliderStyles={sliderStyles}
      />
    );
  }

  log.error(
    "Campaign carousel has incorrect product recommendations config.",
    strategy
  );
  return null;
};

const CampaignCarouselComponent = ({
  module: {
    titleField,
    redirectLink,
    buttons,
    productRecommendation,
    image,
    mobileImage,
    disclaimerInformation,
  },
}: CampaignCarouselComponentProps) => {
  if (productRecommendation === undefined) {
    log.error("Campaign carousel has no recommendation configuration.");
    return null;
  }
  const backgroundColor = color.webBlack.hex;
  const title = {
    text: titleField.title,
    color: titleField.color,
  };
  const imageData = isNotNullOrUndefined(image.url)
    ? {
        desktopSrc: image.url,
        alt: image.alt ?? "",
        mobileSrc: mobileImage?.url,
        hotspot:
          isNotNullOrUndefined(image.hotspot) &&
          isNotNullOrUndefined(image.hotspot.x) &&
          isNotNullOrUndefined(image.hotspot.y)
            ? {
                x: image.hotspot.x,
                y: image.hotspot.y,
              }
            : undefined,
      }
    : undefined;

  if (isNotNullOrUndefined(buttons) && buttons.length > 0) {
    const [button] = buttons;
    return (
      <CampaignCarousel
        backgroundColor={backgroundColor}
        headerType="BUTTON_AS_LINK"
        image={imageData}
        button={{
          href: button.url,
          text: button.displayName,
          backgroundColor:
            button.buttonColor?.toLowerCase() ===
            color.webBlack.hex.toLocaleLowerCase()
              ? color.webBlack.hex
              : color.white.hex,
        }}
        title={title}
        disclaimerInformation={disclaimerInformation}
        Slider={<Slider config={productRecommendation} />}
      />
    );
  }

  if (isNotNullOrUndefined(redirectLink)) {
    return (
      <CampaignCarousel
        backgroundColor={backgroundColor}
        headerType="HEADER_AS_LINK"
        image={imageData}
        link={{
          href: redirectLink.url,
        }}
        title={title}
        disclaimerInformation={disclaimerInformation}
        Slider={<Slider config={productRecommendation} />}
      />
    );
  }

  log.error("Campaign carousel has invalid product recommendation config.");
  return null;
};

export { CampaignCarouselComponent };
